/**
 * Important Notice:
 * 
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

$(function () {
    "use strict";

    $.ajaxSetup({
        cache: true
    });

    /* initialize FlexNav */
    $(".flexnav").flexNav({
        'calcItemWidths': false,
        'hover': false
    });

    /* clone overview pages into submenu */
    $('.menu-item-has-children > a').each(function (index, el) {
        if ($(this).parent().hasClass('append-to-sub')) {
            var target = $(this).next('ul');
            var classes = $(this).parent().attr("class");
            var text = $(this).data("title");
            $(this).clone().prependTo(target).wrap('<li></li>').text(text).parent().attr("class", classes).removeClass('menu-item-has-children item-with-ul current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor append-to-sub');
            $(this).attr("href", "#");
        } else {
            $(this).attr("href", "#");
        }

        $(this).on('click', function (e) {
            e.preventDefault();
        });
    });

    /* cookieBanner */
    var settings = {
        element: '#cookieBannerWrapper'
    };

    var privacy = {
        init: function () {
            if (privacy.readCookie('privacyOk')) {
                $(settings.element).remove();
                return;
            }

            $(settings.element).show();
            $(settings.element).find('p:last-child').append('<a class="closeCookieBanner">OK</a>').on('click', function () {
                $(settings.element).slideUp(400, function () {
                    $(settings.element).remove();
                    privacy.setCookie('privacyOk', true, 730);
                });
            });
        },
        setCookie: function (name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + date.getTime() + expires + "; path=/";
        },
        readCookie: function (name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
    }

    $.fn.privacy = function (options) {
        settings = $.extend(settings, options);
        privacy.init();
        return this;
    };


    $('body').privacy();
});